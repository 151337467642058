<template>
<svg version="1.1" viewBox="0 0 179 50" xmlns="http://www.w3.org/2000/svg">
 <g fill="#facc15" stroke-width="0">
  <path d="m115.09 5.7494-59.413 8.5209 5.1005 35.73 59.413-8.5204z"/>
  <path d="m78.7 4.2599-17.824 2.5564 0.70818 4.9625 17.824-2.5564z"/>
  <path d="m108.41 5.0072e-7 -17.824 2.5559 0.70819 4.9625 17.824-2.5559z"/>
 </g>
 <ellipse transform="matrix(.98987 -.14196 .14132 .98996 0 0)" cx="83.155" cy="38.627" rx="9.4305" ry="9.4514" fill="#fff" stroke="#a67c27" stroke-width="2.5036"/>
 <path d="m5.0073e-7 36.109v-19.419h6.9749q2.8933 0 4.5207 1.2946 1.6404 1.2946 1.6404 3.5861 0 1.5535-0.76207 2.7187-0.74915 1.1651-2.015 1.7089 1.7308 0.45311 2.7124 1.6959 0.98165 1.2299 0.98165 3.0165 0 1.6183-0.80082 2.8352-0.7879 1.2169-2.2345 1.8901-1.4337 0.6732-3.3583 0.6732zm2.8416-10.991h4.017q1.5887 0 2.5316-0.81563 0.9429-0.8156 0.9429-2.175 0-1.3593-0.96873-2.1361-0.95581-0.78973-2.622-0.78973h-3.9008zm0 8.4798h4.1591q1.2529 0 2.1829-0.40133 0.92998-0.41428 1.4466-1.1393 0.51666-0.73794 0.51666-1.7089 0-0.94508-0.49082-1.6442-0.47791-0.69908-1.3691-1.0616-0.89123-0.37545-2.0925-0.37545h-4.3528zm29.23 2.5116h-3.1645l-4.4303-7.5994h-4.7145v7.5994h-2.8416v-19.419h7.5174q2.17 0 3.7199 0.71206 1.55 0.71203 2.3895 2.0325 0.83957 1.3205 0.83957 3.1589 0 2.0585-1.0462 3.4696-1.0462 1.4111-2.9579 2.0067zm-12.309-10.007h4.482q1.9891 0 3.1129-0.90625 1.1237-0.91917 1.1237-2.5504 0-1.6183-1.1237-2.5245-1.1108-0.91917-3.1129-0.91917h-4.482zm14.996 10.007v-19.419h2.8416v19.419zm23.082-2.4727q-1.3433 1.3464-3.2033 2.1102-1.847 0.75089-4.0299 0.75089-2.1829 0-4.0299-0.75089-1.847-0.75087-3.2162-2.1102-1.3562-1.3723-2.1183-3.2107-0.74915-1.8513-0.74915-4.0263 0-2.1879 0.74915-4.0263 0.76207-1.8513 2.1183-3.2107 1.3691-1.3594 3.2162-2.1103 1.847-0.75087 4.0299-0.75087 2.17 0 4.017 0.76382 1.86 0.75089 3.1904 2.0843l-1.9762 2.0196q-0.96873-1.1004-2.325-1.7219-1.3433-0.63437-2.9449-0.63437-1.55 0-2.8545 0.56963-1.3046 0.55671-2.2733 1.5924-0.96873 1.0227-1.5112 2.408-0.52957 1.3852-0.52957 3.0165 0 1.6312 0.52957 3.0165 0.54249 1.3852 1.5112 2.4209 0.96873 1.0227 2.2733 1.5924 1.3046 0.55668 2.8545 0.55668 1.6016 0 2.9449-0.62142 1.3562-0.63434 2.312-1.7348zm5.8253 2.4727h-2.8545v-19.419h2.8416v10.991q0.46499-0.69908 0.90415-1.2946 0.43916-0.59551 0.76207-1.0098l6.8974-8.6869h3.3712l-7.0782 8.9199 8.499 10.499h-3.6812l-6.639-8.2079-3.0224 3.8191z" fill="#312a16" stroke-width="0" style="white-space:pre" aria-label="BRICK"/>
 <path d="m115.77 20.845q-1.0075-0.98391-2.3637-1.5018-1.3562-0.5308-2.8804-0.5308-1.5629 0-2.8804 0.54375-1.3046 0.54373-2.2604 1.5535-0.9429 1.0098-1.4725 2.3951-0.51666 1.3852-0.51666 3.0553 0 1.67 0.51666 3.0682t1.4596 2.421q0.95581 1.0227 2.2474 1.5794 1.3046 0.55668 2.8545 0.55668 1.395 0 2.5704-0.45311 1.1754-0.45311 2.0666-1.2946 0.89123-0.84151 1.4466-2.0196 0.5554-1.1781 0.7104-2.6022h-6.6003v-2.2138h9.119l0.0775 1.0616q0 2.2138-0.68457 4.0521-0.68457 1.8384-1.9504 3.1848-1.2529 1.3335-2.9837 2.0714-1.7179 0.72499-3.7974 0.72499-2.1441 0-3.9653-0.75089-1.8083-0.76382-3.1645-2.1232-1.3433-1.3723-2.0925-3.2107-0.73624-1.8383-0.73624-3.9874 0-2.175 0.74915-4.0133 0.74915-1.8513 2.0925-3.2236 1.3562-1.3723 3.1774-2.1232 1.8212-0.76382 3.9654-0.76382 2.0279 0 3.862 0.69908 1.847 0.69911 3.2808 2.0067zm24.089 15.264h-3.1258l-11.134-14.992v14.992h-2.8416v-19.419h3.1258l11.134 15.005v-15.005h2.8416zm3.7199 0v-19.419h2.8416v19.419zm20.318 0h-14.583v-2.1491l10.708-14.759h-10.191v-2.5116h13.872v2.2785l-10.604 14.629h10.798zm2.8933 0v-19.419h12.077v2.5116h-9.2352v5.8129h8.7057v2.5116h-8.7057v6.0718h9.3644v2.5116z" fill="#312a16" stroke-width="0" style="white-space:pre" aria-label="GNIZE"/>
</svg>
</template>
