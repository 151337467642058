<template>
  <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
    <div class="box">
      <div class="mb-4 space-y-4">

        <h1 class="text-2xl">Terms of Service</h1>

        <h2 class="text-xl">1. Terms</h2>

        <p>By accessing this Website, accessible from brickognize.com, you are agreeing to be bound by these Terms of Service and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright law.</p>

        <h2 class="text-xl">2. Use License</h2>

        <p>Permission is granted to temporarily download one copy of the materials on brickognize.com's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

        <ul class="list-disc pl-4">
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose or for any public display;</li>
            <li>attempt to reverse engineer any software contained on brickognize.com's Website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
        </ul>

        <p>This will let brickognize.com to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>

        <h2 class="text-xl">3. Disclaimer</h2>

        <p>All the materials on brickognize.com’s Website are provided "as is". brickognize.com makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, brickognize.com does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

        <h2 class="text-xl">4. Limitations</h2>

        <p>brickognize.com or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on brickognize.com’s Website, even if brickognize.com or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

        <h2 class="text-xl">5. Revisions and Errata</h2>

        <p>The materials appearing on brickognize.com’s Website may include technical, typographical, or photographic errors. brickognize.com will not promise that any of the materials in this Website are accurate, complete, or current. brickognize.com may change the materials contained on its Website at any time without notice. brickognize.com does not make any commitment to update the materials.</p>

        <h2 class="text-xl">6. Links</h2>

        <p>brickognize.com has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by brickognize.com of the site. The use of any linked website is at the user’s own risk.</p>

        <h2 class="text-xl">7. Site Terms of Service Modifications</h2>

        <p>brickognize.com may revise these Terms of Service for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms of Service.</p>

        <h2 class="text-xl">8. Uploaded content</h2>

        <p>The Users might upload their own content to this Website. By uploading content Users accept that:</p>

        <ul class="list-disc pl-4">
            <li>they are legally allowed to do so and they are not infringing any statutory provisions or third-party rights;</li>
            <li>they grant the Website owner a non-exclusive, worldwide, fully paid-up and royalty-free, irrevocable, perpetual, sub-licensable and transferable license to use, access, store, reproduce, modify, distribute, publish, process into derivative works, train machine learning models, or otherwise exploit such content;</li>
            <li>they can request to remove the uploaded content;</li>
            <li>the Website owner does not moderate the uploaded content;</li>
        </ul>

        <h2 class="text-xl">9. Your Privacy</h2>

        <p>The Website gathers information about users and their behaviour in the following ways:</p>

        <ul class="list-disc pl-4">
            <li>through information entered in forms voluntarily;</li>
            <li>through collecting web server logs;</li>
        </ul>

        <p>The Website gathers information voluntarily provided by users. The Website can also save information about connection settings (time, IP address).</p>

        <h2 class="text-xl">10. Governing Law</h2>

        <p>Any claim related to brickognize.com's Website shall be governed by the laws of Poland without regards to its conflict of law provisions.</p>

        <h2 class="text-xl">11. Other Entities</h2>

        <p>We are not affiliated, associated, authorized, endorsed by, or in any way officially connected with BrickLink, BrickOwl, Rebrickable, Brickset or the LEGO group, or any of their subsidiaries or affiliates. The names BrickLink, BrickOwl, Rebrickable, Brickset and LEGO, as well as related names, marks, emblems, and images, are registered trademarks of their respective owners.</p>

      </div>
    </div>
  </div>
</template>
