<template>
    <SearchComponent ref="search"/>
    <canvas ref="canvas_home" class="hidden"></canvas>

    <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
        <div class="box text-center">
            <div class="mb-4 space-y-4">
                <p>Brickognize is an image search app for Lego bricks.</p>
                <p>Currently, it recognizes only a single part, minifigure or set per image.</p>
                <p class="italic">This is still a beta version. Things might not work as expected. <a class="underline hover:text-gray-400" href="mailto:piotr.rybak@brickognize.com">Let me know</a> if that happens.</p>
            </div>
            <p class="text-2xl mb-2 mt-6">Search</p>
            <span title="upload image">
                <label for="search-input-home" class="btn w-24 bg-red-500 hover:bg-red-50 border-red-500"><UploadIcon class="inline-block w-6 h-6"/>upload</label>
                <input type="file" accept="image/*" @change="$refs.search.predict_from_upload($event, $refs.canvas_home)" id="search-input-home" class="hidden">
            </span>
            <span v-if="hasCamera" title="take a photo">
                <span v-if="isMobile">
                    <label for="search-input-home-mobile" class="btn w-24 bg-yellow-500 hover:bg-yellow-50 border-yellow-500"><CameraIcon class="inline-block w-6 h-6"/>camera</label>
                    <input type="file" capture="environment" accept="image/*" @change="$refs.search.predict_from_upload($event, $refs.canvas_home)" id="search-input-home-mobile" class="hidden">
                </span>
                <span v-else>
                    <router-link class="btn w-24 bg-yellow-500 hover:bg-yellow-50 border-yellow-500" to="/search"><CameraIcon class="inline-block w-6 h-6"/>camera</router-link>
                </span>
            </span>
            <span v-else title="camera not available">
                <div class="btn w-24 bg-gray-300 border-gray-300 text-gray-500 hover:text-gray-500 cursor-not-allowed"><CameraIcon class="inline-block w-6 h-6"/>camera</div>
            </span>
        </div>
    </div>
</template>

<script>
    import camera from '@/camera.js';
    import SearchComponent from '@/components/SearchComponent.vue'
    import CameraIcon from '@/icons/CameraIcon.vue';
    import UploadIcon from '@/icons/UploadIcon.vue';

    export default {
        data() {
            return {
                hasCamera: true,
                isMobile: true,
            }
        },
        mounted() {
            camera.set_is_mobile(this.$data);
            camera.set_has_camera(this.$data);
        },
        components: {
            SearchComponent,
            CameraIcon,
            UploadIcon,
        },
    };
</script>
