<template>
  <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
    <div class="box">
      <div class="mb-4 space-y-4">
        <p>Brickognize is an image search app for Lego bricks. You can take a photo and find any part, minifigure or set.</p>
        <p>This is still an early beta version and things might not work as expected. <a class="underline hover:text-gray-400" href="mailto:piotr.rybak@brickognize.com">Let me know</a> if that happens. Or if you have any other feedback :)</p>
      </div>
    </div>
  </div>
</template>
