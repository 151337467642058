<template>
    <SearchComponent ref="search"/>
    <canvas ref="canvas_navbar" class="hidden"></canvas>

    <nav class="fixed w-full h-16 z-20 top-0 left-0 bg-white border-b border-gray-300 px-2 sm:px-4 py-3">
        <div class="container flex flex-wrap items-center justify-between mx-auto lg:w-1/2 md:w-full">
            <router-link to="/" class="pl-2 sm:pl-4 lg:pl-2">
                <MainLogo class="inline-block h-10 pl-1"/>
            </router-link>
            <div class="w-auto" id="navbar-default">
                <ul class="flex flex-row pr-2 sm:pr-4 lg:pr-2 mt-0 space-x-8 text-gray-600 hover:text-gray-800">
                    <li class="block hover:text-gray-400" title="upload image">
                        <label for="search-input-navbar" class="block cursor-pointer hover:text-gray-400"><UploadIcon class="inline-block w-6 h-6"/></label>
                        <input type="file" accept="image/*" @change="$refs.search.predict_from_upload($event, $refs.canvas_navbar)" id="search-input-navbar" class="hidden">
                    </li>
                    <li v-if="hasCamera" class="block hover:text-gray-400 pr-1" title="take a photo">
                        <span v-if="isMobile">
                            <label for="search-input-navbar-mobile" class="block cursor-pointer hover:text-gray-400"><CameraIcon class="inline-block w-6 h-6"/></label>
                            <input type="file" capture="environment" accept="image/*"  @change="$refs.search.predict_from_upload($event, $refs.canvas_navbar)" id="search-input-navbar-mobile" class="hidden">
                        </span>
                        <span v-else>
                            <router-link to="/search"><CameraIcon class="inline-block w-6 h-6"/></router-link>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import camera from '@/camera.js';
    import SearchComponent from '@/components/SearchComponent.vue'
    import MainLogo from '@/components/MainLogo.vue';
    import CameraIcon from '@/icons/CameraIcon.vue';
    import UploadIcon from '@/icons/UploadIcon.vue';

    export default {
        data() {
            return {
                hasCamera: true,
                isMobile: true,
            }
        },
        mounted() {
            camera.set_is_mobile(this.$data);
            camera.set_has_camera(this.$data);
        },
        components: {
            SearchComponent,
            MainLogo,
            CameraIcon,
            UploadIcon,
        },
    };
</script>



