<template>
  <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
    <div class="box text-center">
      <QueryPreview v-if="listing" :query="listing.query" :bounding_box="listing.results.bounding_box"/>
    </div>
    <ResultsListing v-if="listing" :items="listing.results.items"/>
  </div>
</template>

<script>
import axios from 'axios';
import ResultsListing from '@/components/ResultsListing.vue';
import QueryPreview from '@/components/QueryPreview.vue';

import { state } from '@/state.js';
import { Listing } from '@/models.js';

export default {
  data() {
    return {
      state: state,
    }
  },
  methods: {
    getListing (listing_id) {
      axios.get(`/listing/${listing_id}`).then(
        response => {
          this.state.listing[listing_id] = new Listing({
            results: response.data.results,
            query: `data:${response.data.query.content_type};base64,${response.data.query.b64_encoded}`
          });
        }
      ).catch(
        () => { this.$router.push('/404'); }
      )
    }
  },
  name: 'ResultsView',
  computed: {
    listing () {
      const listing_id = this.$route.params.listingId
      if ( !(listing_id in this.state.listing) ) {
        this.getListing(listing_id);
      }
      return this.state.listing[listing_id];
    }
  },
  components: {
    ResultsListing,
    QueryPreview,
  }
}
</script>
