<template>
  <metainfo></metainfo>
  <div id="app" class="flex flex-col h-screen text-gray-800">
    <NavBar/>
    <div class="flex-grow bg-gray-50 mt-16">
      <router-view/>
    </div>
    <FooterComponent/>
  </div>
  <AnalyticsTracker/>
</template>

<script type="text/javascript">
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "./components/FooterComponent.vue";
import { useMeta } from 'vue-meta'

export default {
  components: {
    NavBar,
    FooterComponent,
  },
  methods: {
    handleKeys(e) {
      if (e.code == 'Backspace') {
        this.$router.back();
      }
    }
  },
  setup () {
    useMeta({
      htmlAttrs: { lang: 'en' },
      description: { content: 'Brickognize is an image search for LEGO bricks!' },
    })
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeys);
  },
  unmounted() {
    window.removeEventListener('keyup', this.handleKeys);
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>