<template>
<a class="min-w-max text-center" :href="url" target="blank" @click="sendFeedback('external-site')">
  <div class="group w-auto border border-gray-300 hover:shadow-lg rounded my-4 bg-white">
    <div class="border-b border-gray-300 py-4 px-2">
      <p class="text-xl">{{ name }}</p>
      <div class="flex flex-wrap justify-center items-center space-x-2 mt-2">
        <span class="text-gray-600 text-lg">Bricklink ID: {{ id }}</span>
        <CopyIcon @click.stop.prevent="copyItemId()" class="text-xl" :class="{ 'text-red-500': active, 'text-gray-600': !active, 'hover:text-gray-800': !active}"/>
      </div>
    </div>
    <img class="p-4 inline mx-auto" :src="imgUrl" @error="replaceBrokenImage" />
  </div>
</a>
</template>

<script>
  import axios from 'axios';
  import CopyIcon from '@/icons/CopyIcon.vue';

  const BROKEN_IMAGE_PLACEHOLDER = 'https://storage.googleapis.com/brickognize-static/static/missing-image.png';

  export default {
    props: ['item_rank', 'name', 'id', 'type', 'url', 'imgUrl'],
    data() {
      return {
        active: false,
      };
    },
    methods: {
      sendFeedback(source) {
        axios.post('/feedback/', {
          listing_id: this.$route.params.listingId,
          item_id: this.$props.id,
          item_type: this.$props.type,
          is_prediction_correct: true,
          source: source,
          item_rank: this.$props.item_rank
        }).catch(() => {});
      },
      copyItemId() {
        const storage = document.createElement('textarea');
        storage.value = this.$props.id;
        document.body.appendChild(storage);
        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(storage);
        this.active = true;
        this.sendFeedback('copy-btn');
      },
      replaceBrokenImage(event) {
        event.target.src = BROKEN_IMAGE_PLACEHOLDER;
      }
    },
    components: {
      CopyIcon,
    },
  }
</script>
