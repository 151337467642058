<template>
    <div class="relative inline-block">
        <div class="absolute border-2 border-red-500" :style="this.scaledBoundingBox()"></div>
        <img id="query-preview" :src="query" class="mx-auto rounded" style="max-width: 200px; max-height: 200px; width: auto; height: auto;">
    </div>
</template>

<script>
    import { state } from '@/state.js'

    export default {
        props: ['query', 'bounding_box'],
        name: 'query-preview',
        data() {
            return {
                state: state,
            }
        },
        methods: {
            scaledBoundingBox() {
                const bbox = {
                    left: 100 * this.bounding_box.left / this.bounding_box.image_width,
                    top: 100 * this.bounding_box.upper / this.bounding_box.image_height,
                    width: 100 * (this.bounding_box.right - this.bounding_box.left) / this.bounding_box.image_width,
                    height: 100 * (this.bounding_box.lower - this.bounding_box.upper) / this.bounding_box.image_height,
                };

                const padding = 3;
                bbox['left'] = Math.max(0, bbox['left'] - padding) + '%';
                bbox['top'] = Math.max(0, bbox['top'] - padding) + '%';
                bbox['width'] = Math.min(100, bbox['width'] + 2*padding) + '%';
                bbox['height'] = Math.min(100, bbox['height'] + 2*padding) + '%';

                return bbox
            }
        }
    }
</script>
