<template>
    <SearchComponent ref="search"/>

    <div class="fixed inset-0 z-30 bg-black flex items-center justify-center h-full overflow-hidden">
        <video ref="video" autoPlay={true} playsInline={true} muted={true} class="absolute w-auto min-w-full min-h-full max-w-none"></video>
        <canvas ref="canvas_search" class="absolute w-auto min-w-full min-h-full max-w-none"></canvas>

        <div class="absolute flex bottom-0 min-w-full bg-black bg-opacity-50 py-4 items-center justify-center space-x-12">
            <label for="search-input-search" class="text-white inline-block cursor-pointer hover:text-gray-500" title="upload image">
                <UploadIcon class="w-8 h-8"/>
            </label>
            <input type="file" accept="image/*" @change="$refs.search.predict_from_upload($event, $refs.canvas_search)" id="search-input-search" class="hidden">
            <div @click="$refs.search.predict_from_video($refs.video, $refs.canvas_search)" class="text-white inline-block cursor-pointer w-16 h-16 border-white border-2 rounded-full flex items-center justify-center" title="take a photo">
                <div class="inline-block bg-white w-14 h-14 rounded-full hover:bg-opacity-75"></div>
            </div>
            <router-link class="text-white inline-block cursor-pointer hover:text-gray-500" to="/" title="go back">
                <BackIcon class="w-8 h-8"/>
            </router-link>
        </div>
    </div>
</template>

<script>
    import camera from '@/camera.js';
    import SearchComponent from '@/components/SearchComponent.vue'
    import UploadIcon from '@/icons/UploadIcon.vue';
    import BackIcon from '@/icons/BackIcon.vue';

    export default {
        mounted() {
            camera.start_camera(this.$refs.video).catch(() => {this.$router.back();});
            window.addEventListener('keyup', this.handleKeys);
        },
        beforeUnmount() {
            camera.stop_camera(this.$refs.video);
        },
        unmounted() {
            window.removeEventListener('keyup', this.handleKeys);
        },
        components: {
            SearchComponent,
            UploadIcon,
            BackIcon,
        },
        methods: {
            handleKeys(e) {
                if (e.code == 'Space') {
                    this.$refs.search.predict_from_video(this.$refs.video, this.$refs.canvas_search);
                }
            }
        }
    }
</script>
