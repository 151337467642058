import axios from 'axios';

import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import router from './router'
import './index.css'
import posthog from 'posthog-js'
import * as Sentry from "@sentry/vue";

export const SENTRY_DSN = 'https://5c372dd7f13cb9273bdc6b9cca271950@o4507186502434816.ingest.de.sentry.io/4507187771146320';
export const DEV_SENTRY_SAMPLE_RATE = 1.0;
export const PROD_SENTRY_SAMPLE_RATE = 0.1;
export const LOCAL_ENV = 'local';
export const DEV_ENV = 'development';
export const PROD_ENV = 'production';
export const PROD_HOST = 'brickognize.com';
export const DEV_HOST = 'dev.brickognize.com';
export const LOCAL_HOST = 'localhost';
export const PROD_BACKEND_URL = 'https://api.brickognize.com';
export const DEV_BACKEND_URL = 'https://api.dev.brickognize.com';
export const LOCAL_BACKEND_URL = 'http://localhost:8000';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = getBackendUrl();
let env = getEnv();

const app = createApp(App)
    .use(router)
    .use(createMetaManager())

app.mount('#app')

posthog.init('phc_iWqhcalblEu5YrjroCRQCTZMvLEuHhSsV71WCISAa7M', {
    api_host: 'https://eu.posthog.com',
    autocapture: false,
    capture_pageview: true,
    capture_pageleave: true,
})

if (sentryEnabled()) {
    Sentry.init({
      app,
      environment: env,
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: ((env === PROD_ENV) ? PROD_SENTRY_SAMPLE_RATE : DEV_SENTRY_SAMPLE_RATE),
      tracePropagationTargets: [getBackendUrl()],
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: ((env === PROD_ENV) ? PROD_SENTRY_SAMPLE_RATE : DEV_SENTRY_SAMPLE_RATE),
    });
}

export function getBackendUrl() {
    const env = getEnv()
    switch (env) {
        case LOCAL_ENV:
            return LOCAL_BACKEND_URL;
        case DEV_ENV:
            return DEV_BACKEND_URL;
        case PROD_ENV:
            return PROD_BACKEND_URL;
        default:
            return PROD_BACKEND_URL;
    }
}

function sentryEnabled() {
    return env !== LOCAL_ENV;
}

function getEnv() {
    const hostname = window.location.hostname
    switch (hostname) {
        case LOCAL_HOST:
            return LOCAL_ENV;
        case DEV_HOST:
            return DEV_ENV;
        case PROD_HOST:
            return PROD_ENV;
        default:
            return PROD_ENV;
    }
}
