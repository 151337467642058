import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SearchView from '../views/SearchView.vue'
import ResultsView from '../views/ResultsView.vue'
import AboutView from '../views/AboutView.vue'
import TOSView from '../views/TOSView.vue'
import NotFoundView from '../views/NotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: {title: 'Brickognize - easily identify LEGO bricks'}
  },
  {
    path: '/search',
    name: 'SearchView',
    component: SearchView,
    meta: {title: 'Search | Brickognize'}
  },
  {
    path: '/results/:listingId',
    name: 'ResultsView',
    component: ResultsView,
    meta: {title: 'Results | Brickognize'}
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
    meta: {title: 'About | Brickognize'}
  },
  {
    path: "/terms-of-service",
    name: "TOSView",
    component: TOSView,
    meta: {title: 'Terms of Service | Brickognize'}
  },
  {
    path: '/404',
    alias: ['/:pathMatch(.*)*'],
    name: 'NotFoundView',
    component: NotFoundView,
    meta: {title: 'Page not found | Brickognize'}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'Brickognize - easily identify LEGO bricks';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
