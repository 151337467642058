<template>
  <div id="results-listing" v-if="items">
    <div v-if="items.length === 0" class="text-center">
      <div class="group w-auto border border-gray-300 rounded my-4 bg-white p-4">
        <p class="text-xl">No results found</p>
        <p class="text-gray-600 mt-2">We couldn't find any bricks or minifigures in this image. Please try again with a different image.</p>
      </div>
    </div>
    <template v-else>
      <ResultItem v-for="(item, item_rank) in items" :item_rank="item_rank" :key="item.id" :name="item.name" :id="item.id" :type="item.type" :url="item.external_sites[0].url" :imgUrl="item.img_url" />
    </template>
  </div>
</template>

<script>
import ResultItem from './ResultItem';

export default {
  props: ['items'],
  components: {
    ResultItem
  }
}
</script>
