<template>
    <SpinnerComponent v-if="isPredicting"/>
</template>

<script>
    import axios from 'axios';
    import posthog from 'posthog-js';
    import SpinnerComponent from '@/components/SpinnerComponent';

    import { state } from '@/state.js';
    import { Listing } from '@/models.js';

    export default {
        name: 'part-search',
        data() {
            return {
                isPredicting: false,
                state: state,
            }
        },
        methods: {
            create_downscaled_canvas(canvas, img_src, src_width, src_height, max_size=1024) {
                const context = canvas.getContext('2d', {alpha: true});

                let scaleProportions;
                if (src_width <= max_size && src_height <= max_size) {
                    scaleProportions = 1;
                } else if (src_height <= src_width) {
                    scaleProportions = max_size / src_width;
                } else {
                    scaleProportions = max_size / src_height;
                }
                canvas.width = src_width * scaleProportions;
                canvas.height = src_height * scaleProportions;
                context.drawImage(img_src, 0, 0, canvas.width, canvas.height);

                return canvas
            },
            create_file(dataURI, img_name) {
                // convert base64/URLEncoded data component to raw binary data held in a string
                var byteString;
                if (dataURI.split(',')[0].indexOf('base64') >= 0)
                    byteString = atob(dataURI.split(',')[1]);
                else
                    byteString = unescape(dataURI.split(',')[1]);

                // separate out the mime component
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

                // write the bytes of the string to a typed array
                var intArray = new Uint8Array(byteString.length);
                for (var i = 0; i < byteString.length; i++) {
                    intArray[i] = byteString.charCodeAt(i);
                }

                const blob = new Blob([intArray]);
                const file = new File([blob], img_name, {type:mimeString})
                return file
            },
            before_predict() {
                this.isPredicting = true;
                posthog.capture('search', { state: 'start', platform: 'web' });
            },
            predict_from_canvas(canvas, img_name, img_type, quality=0.95) {
                const data = new FormData();
                const dataURI = canvas.toDataURL(img_type, quality)
                data.append('query_image', this.create_file(dataURI, img_name));

                const config = {
                    header: {
                        'Content-Type': 'multipart/form-data',
                    }
                };
                axios.post('/predict/', data, config).then(
                    response => {
                        const listing_id = response.data.listing_id;
                        state.listing[listing_id] = new Listing({
                            results: response.data,
                            query: dataURI
                        });
                        this.isPredicting = false;
                        if (response.data.items.length > 0) {
                            const top_item = response.data.items[0];
                            this.$router.push(`/results/${listing_id}/?item=${top_item['type']}/${top_item['id']}`);
                        } else {
                            this.$router.push(`/results/${listing_id}`);
                        }
                    }
                ).catch((error) => {
                    posthog.capture('search', { state: 'error', platform: 'web' });
                    this.isPredicting = false;
                    if(Object.prototype.hasOwnProperty.call(error, 'response')){
                        alert(error.response.data.detail);
                    } else {
                        alert('Unknown error');
                    }
                })
            },
            predict_from_video(video, canvas) {
                this.before_predict();
                const downscaled_canvas = this.create_downscaled_canvas(canvas, video, video.videoWidth, video.videoHeight);
                this.predict_from_canvas(downscaled_canvas, 'image.jpg', 'image/jpeg');
            },
            predict_from_upload(event, canvas) {
                this.before_predict();
                if (event.target.files.length !== 0) {
                    const imageFile = event.target.files[0];
                    const img_upload = document.createElement('img');
                    img_upload.src = URL.createObjectURL(imageFile);
                    img_upload.onload = () => {
                        const downscaled_canvas = this.create_downscaled_canvas(canvas, img_upload, img_upload.width, img_upload.height);
                        this.predict_from_canvas(downscaled_canvas, imageFile.name, imageFile.type);
                    };
                } else {
                    this.isPredicting = false;
                }
            },
        },
        components: {
            SpinnerComponent
        }
    };
</script>
